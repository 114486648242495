import { INITIAL_ORDER_SEARCH_PARAMS } from 'src/views/orders/partials/Constants'
import { ORDER_FIELD_LIST, SET_ORDER_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  fields: [],
  searchParams: INITIAL_ORDER_SEARCH_PARAMS,
}
export default function OrderReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_FIELD_LIST:
      return { ...state, fields: action.payload }
    case SET_ORDER_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
