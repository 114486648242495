import { SELECT_MAXIMUM_LIMIT } from 'src/constants/Constants'
import AppointmentDataService from 'src/services/AppointmentDataService'
import BillingCategoryDataService from 'src/services/BillingCategoryDataService'
import BillingLineChargesDataService from 'src/services/BillingLineChargesDataService'
import BillingService from 'src/services/BillingService'
import BillingUOMDataService from 'src/services/BillingUOMDataService'
import CarrierDataService from 'src/services/CarrierDataService'
import ClientDataService from 'src/services/ClientDataService'
import DepartmentDataService from 'src/services/DepartmentDataService'
import ErrorsDataService from 'src/services/ErrorsDataService'
import ItemConditionsDataService from 'src/services/ItemConditionsDataService'
import ItemLinguisticsDataService from 'src/services/ItemLinguisticsDataService'
import ItemStatusesDataService from 'src/services/ItemStatusesDataService'
import ItemTypesDataService from 'src/services/ItemTypesDataService'
import OrderTypesService from 'src/services/OrderTypesService'
import OrdersDataService from 'src/services/OrdersDataService'
import PickingDataService from 'src/services/PickingDataService'
import ProcessingTypeDataService from 'src/services/ProcessingTypeDataService'
import ProvinceDataService from 'src/services/ProvinceDataService'
import QuantityTypesDataService from 'src/services/QuantityTypesDataService'
import ReceivingDataService from 'src/services/ReceivingDataService'
import ReturnStatusService from 'src/services/ReturnStatusService'
import SalutationDataService from 'src/services/SalutationDataService'
import SettingDataService from 'src/services/SettingDataService'
import ShippingMethodsDataService from 'src/services/ShippingMethodsDataService'
import SupplierDataService from 'src/services/SupplierDataService'
import SupplyTypesDataService from 'src/services/SupplyTypesDataService'
import TransactionsDataService from 'src/services/TransactionsDataService'
import UomTypesDataService from 'src/services/UomTypesDataService'
import ZoneService from 'src/services/ZoneService'
import { isNonEmptyArray } from 'src/utils/helpers'
import CountryDataService from '../services/CountryDataService'
import CurrenciesDataService from '../services/CurrenciesDataService'
import DeliveryTypeDataService from '../services/DeliveryTypeDataService'
import DoorTypeDataService from '../services/DoorTypeDataService'
import HolidaysDataService from '../services/HolidaysDataService'
import ItemReasonsDataService from '../services/ItemReasonsDataService'
import JobsDataService from '../services/JobsDataService'
import LanguageDataService from '../services/LanguageDataService'
import LengthClassDataService from '../services/LengthClassDataService'
import LocationCategoryDataService from '../services/LocationCategoryDataService'
import ModificationTypeDataService from '../services/ModificationTypeDataService'
import PurchaseOrderDataService from '../services/PurchaseOrderDataService'
import TaxClassesDataService from '../services/TaxClassesDataService'
import WarehouseDataService from '../services/WarehouseDataService'
import WeightClassDataService from '../services/WeightClassDataService'
import cookiesUtils from '../utils/cookie-utils'
import {
  APPOINTMENT_STATUS_DATA,
  APPOINTMENT_TASKS_DATA,
  BILLING_CATEGORIES_DATA,
  BILLING_LINE_CHARGES_DATA,
  BILLING_UOM_DATA,
  CARRIERS_DATA,
  CHANGE_LANGUAGE_DATA,
  CLIENTS_DATA,
  COUNTRIES_DATA,
  CURRENCY_DATA,
  DELIVERY_STATUS_DATA,
  DELIVERY_TYPE_DATA,
  DEPARTMENTS_DATA,
  DOOR_TYPE_DATA,
  ERRORS_DATA,
  HOLIDAY_DATA,
  INVOICE_STATUS_DATA,
  ITEM_CONDITIONS_DATA,
  ITEM_LINGUISTICS_DATA,
  ITEM_REASON_DATA,
  ITEM_STATUS_DATA,
  ITEM_TYPES_DATA,
  JOBS_STATUS_DATA,
  JOBS_TYPE_DATA,
  LANGUAGE_DATA,
  LENGTH_CLASS_DATA,
  LOCATION_CATEGORY_DATA,
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGOUT_BEGIN,
  MODIFICATION_TYPE_DATA,
  NO_USER,
  ORDER_ITEM_STATUS_DATA,
  ORDER_PACKAGE_STATUS_DATA,
  ORDER_STATUS_DATA,
  ORDER_TYPES_DATA,
  PICKABLE_TYPES_DATA,
  PICKING_METHODS_DATA,
  PICKING_SKIPS_DATA,
  PICKING_SKIP_REASONS_DATA,
  PO_CONDITIONS_DATA,
  PO_ITEM_STATUSES_DATA,
  PO_STATUSES_DATA,
  PROCESSING_TYPES_DATA,
  PROVINCES_DATA,
  QUANTITY_TYPES_DATA,
  RECEIVING_ITEM_CONDITION_DATA,
  RECEIVING_ITEM_REASON_DATA,
  RECEIVING_ITEM_STATUS_DATA,
  RECEIVING_PO_REASON_DATA,
  RECEIVING_PO_STATUS_DATA,
  RECEIVING_STATUS_DATA,
  REPLENISH_STATUS_DATA,
  RETURN_ACTIONS_DATA,
  RETURN_ITEM_ACTIONS_DATA,
  RETURN_ITEM_CONDITIONS_DATA,
  RETURN_ITEM_REASONS_DATA,
  RETURN_ITEM_STATUSES_DATA,
  RETURN_REASON_DATA,
  RETURN_STATUSES_DATA,
  SALUTATION_DATA,
  SERVICES_DATA,
  SETTING_DATA,
  SHIPPING_METHODS_DATA,
  SUPPLIERS_DATA,
  SUPPLY_TYPES_DATA,
  TAX_DATA,
  TRANSACTION_TYPES_DATA,
  UOM_TYPE_DATA,
  USER_DATA,
  USER_NOT_SAVED_DATA,
  WAREHOUSE_DATA,
  WEIGHT_CLASS_DATA,
  ZONE_CATEGORIES_DATA,
} from './actionType'

export const userLoginData = (data) => ({
  type: USER_DATA,
  payload: data,
})

export const languagesData = (data) => ({
  type: LANGUAGE_DATA,
  payload: data,
})

export const isUserDataSaved = (data) => ({
  type: USER_NOT_SAVED_DATA,
  payload: data,
})

export const userLanguageData = (data) => ({
  type: CHANGE_LANGUAGE_DATA,
  payload: data,
})

export const noUser = () => ({
  type: NO_USER,
})

export const startLogin = () => ({
  type: LOGIN_BEGIN,
})

export const loginError = (msg) => ({
  type: LOGIN_ERROR,
  payload: msg,
})

export function logout() {
  return (dispatch) => {
    dispatch(logoutBegin())
    cookiesUtils.logout()
  }
}

export const logoutBegin = () => ({
  type: LOGOUT_BEGIN,
})

export const fetchCountries = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      CountryDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: COUNTRIES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchProvinces = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ProvinceDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: PROVINCES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchCarriers = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      CarrierDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: CARRIERS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchServices = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      CarrierDataService.getServices().then((res) => {
        dispatch({ type: SERVICES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchOrderTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      OrderTypesService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ORDER_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchAppointmentTasks = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      AppointmentDataService.getTasks().then((res) => {
        dispatch({ type: APPOINTMENT_TASKS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchAppointmentStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      AppointmentDataService.getStatuses().then((res) => {
        dispatch({ type: APPOINTMENT_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchSuppliers = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      SupplierDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: SUPPLIERS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchLanguages = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      LanguageDataService.getAll({ list: 'all' }).then((res) => {
        if (res.data?.success) {
          dispatch({ type: LANGUAGE_DATA, payload: res.data.data })
        }
      })
    }
    return data
  }
}

export const fetchCurrencies = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      CurrenciesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: CURRENCY_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchModificationTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ModificationTypeDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: MODIFICATION_TYPE_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchItemReasons = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ItemReasonsDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_REASON_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchWarehouses = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      WarehouseDataService.getAll({ list: 'all', limit: SELECT_MAXIMUM_LIMIT }).then((res) => {
        dispatch({ type: WAREHOUSE_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchDeliveryTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      DeliveryTypeDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: DELIVERY_TYPE_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchPurchaseOrderConditions = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      PurchaseOrderDataService.getConditions().then((res) => {
        dispatch({ type: PO_CONDITIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchPurchaseOrderStatuses = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      PurchaseOrderDataService.getStatuses().then((res) => {
        dispatch({ type: PO_STATUSES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchPurchaseOrderItemStatuses = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      PurchaseOrderDataService.getPurchaseOrderItemStatuses().then((res) => {
        dispatch({ type: PO_ITEM_STATUSES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchShippingMethods = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ShippingMethodsDataService.getAll().then((res) => {
        dispatch({ type: SHIPPING_METHODS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchOrderStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      OrdersDataService.getStatus().then((res) => {
        dispatch({ type: ORDER_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchOrderItemStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      OrdersDataService.getOrderItemStatus().then((res) => {
        dispatch({ type: ORDER_ITEM_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchOrderPackageStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      OrdersDataService.getOrderPackageStatus().then((res) => {
        dispatch({ type: ORDER_PACKAGE_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReceivingStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReceivingDataService.getStatuses().then((res) => {
        dispatch({ type: RECEIVING_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchDeliveryStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReceivingDataService.getDeliveryStatuses().then((res) => {
        dispatch({ type: DELIVERY_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchUomTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      UomTypesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: UOM_TYPE_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchSalutations = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      SalutationDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: SALUTATION_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchLocationCategories = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      LocationCategoryDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: LOCATION_CATEGORY_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchLengthClass = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      LengthClassDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: LENGTH_CLASS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchDepartments = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      DepartmentDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: DEPARTMENTS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchWeightClass = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      WeightClassDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: WEIGHT_CLASS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchSupplyTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      SupplyTypesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: SUPPLY_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchItemTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ItemTypesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchItemStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ItemStatusesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnReason = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReturnStatusService.getReturnReason().then((res) => {
        dispatch({ type: RETURN_REASON_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnStatuses = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReturnStatusService.getReturnStatuses().then((res) => {
        dispatch({ type: RETURN_STATUSES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnActions = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReturnStatusService.getReturnActions().then((res) => {
        dispatch({ type: RETURN_ACTIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnItemReasons = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReturnStatusService.getReturnItemReasons().then((res) => {
        dispatch({ type: RETURN_ITEM_REASONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnItemConditions = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReturnStatusService.getReturnItemConditions().then((res) => {
        dispatch({ type: RETURN_ITEM_CONDITIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnItemActions = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReturnStatusService.getReturnItemActions().then((res) => {
        dispatch({ type: RETURN_ITEM_ACTIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnItemStatuses = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReturnStatusService.getReturnItemStatuses().then((res) => {
        dispatch({ type: RETURN_ITEM_STATUSES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchJobsType = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      JobsDataService.getJobsType().then((res) => {
        dispatch({ type: JOBS_TYPE_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchJobsStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      JobsDataService.getJobsStatus().then((res) => {
        dispatch({ type: JOBS_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchSettings = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data) || !(data instanceof Array)) {
      SettingDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: SETTING_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchBillingCategories = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      BillingCategoryDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: BILLING_CATEGORIES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchLineChargesCategories = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      BillingLineChargesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: BILLING_LINE_CHARGES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchBillingUOMTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      BillingUOMDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: BILLING_UOM_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchHolidays = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      HolidaysDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: HOLIDAY_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchDoorTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      DoorTypeDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: DOOR_TYPE_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchTaxes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      TaxClassesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: TAX_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchAppointmentStatuses = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      AppointmentDataService.getStatuses({ list: 'all' }).then((res) => {
        dispatch({ type: APPOINTMENT_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReceivingItemReasons = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReceivingDataService.getReceivingItemReasons().then((res) => {
        dispatch({ type: RECEIVING_ITEM_REASON_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReceivingItemStatuses = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReceivingDataService.getReceivingItemStatuses().then((res) => {
        dispatch({ type: RECEIVING_ITEM_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReceivingItemConditions = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReceivingDataService.getReceivingItemConditions().then((res) => {
        dispatch({ type: RECEIVING_ITEM_CONDITION_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReceivingPOStatuses = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReceivingDataService.getReceivingPOStatuses().then((res) => {
        dispatch({ type: RECEIVING_PO_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReceivingPOReasons = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ReceivingDataService.getReceivingPOReasons().then((res) => {
        dispatch({ type: RECEIVING_PO_REASON_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchQuantityTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      QuantityTypesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: QUANTITY_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchItemConditions = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ItemConditionsDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_CONDITIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchItemLinguistics = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ItemLinguisticsDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_LINGUISTICS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchPickingMethods = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      PickingDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: PICKING_METHODS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchPickableTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      PickingDataService.getPickableTypes({ list: 'all' }).then((res) => {
        dispatch({ type: PICKABLE_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchErrors = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ErrorsDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ERRORS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchClients = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ClientDataService.getAll({ list: 'all', limit: SELECT_MAXIMUM_LIMIT }).then((res) => {
        dispatch({ type: CLIENTS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchPickingSkips = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      PickingDataService.getPickingSkips({ list: 'all' }).then((res) => {
        dispatch({ type: PICKING_SKIPS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchPickingSkipReasons = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      PickingDataService.getSkipReasons({ list: 'all' }).then((res) => {
        dispatch({ type: PICKING_SKIP_REASONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchTransactionTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      TransactionsDataService.getTransactionTypes({ list: 'all' }).then((res) => {
        dispatch({ type: TRANSACTION_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchInvoiceStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      BillingService.getInvoiceStatuses({ list: 'all' }).then((res) => {
        dispatch({ type: INVOICE_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchProcessingTypes = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ProcessingTypeDataService.getProcessingTypes({ list: 'all' }).then((res) => {
        dispatch({ type: PROCESSING_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchZoneCategories = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ZoneService.getZoneCategories({ list: 'all' }).then((res) => {
        dispatch({ type: ZONE_CATEGORIES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReplenishStatus = (data) => {
  return (dispatch) => {
    if (!isNonEmptyArray(data)) {
      ZoneService.getReplenishStatuses({ list: 'all' }).then((res) => {
        dispatch({ type: REPLENISH_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}
