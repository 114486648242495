import { INITIAL_INVENTORY_SEARCH_PARAMS } from 'src/views/inventory/inventory/partials/Constants'
import { SET_INVENTORY_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  searchParams: INITIAL_INVENTORY_SEARCH_PARAMS,
}
export default function InventoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INVENTORY_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
