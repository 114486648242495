import { COUNTRIES, ORDER_STATE, ORDER_TYPE } from 'src/constants/Constants'

export const INITIAL_ORDER_SEARCH_PARAMS = {
  id: '',
  client_id: '',
  warehouse_id: '',
  order_number_like: '',
  internal_order_like: '',
  company: '',
  batch_id: '',
  first_name: '',
  last_name: '',
  email: '',
  city: '',
  postal_code: '',
  inside_delivery: '',
  lift_gate_required: '',
  appointment_required: '',
  deliver_by_date: '',
  processing_priority: '',
  order_type_id: '',
  date_field: '',
  start_date: '',
  end_date: '',
  handling_priority: '',
  order_status_id: '',
  order_state_id: ORDER_STATE.OPEN,
  picking_method_id: '',
  sku: '',
  reference1: '',
  client_po: '',
  limit: 15,
  page: 1,
  order_by: 'id',
  sort_method: 'desc',
}

export const INIT_NEW_SHIPPING_PACKAGE = {
  package_id: '',
  carrier_id: '',
  carrier_name: '',
  tracking_number: '',
}

export const ADDITIONAL_EMPLOYEE_FILTERS = [
  'batch_id',
  'warehouse_id',
  'picking_method_id',
  'order_state_id',
  'tote_id',
  'language_id',
  'parent_id',
  'processing_type_id',
  'created_at',
  'updated_at',
  'service_id',
  'carrier_id',
  'delivery_by_date',
  'order_type_id',
  'proxpert_import_at',
]

export const INITIAL_INTEGRATION_ORDER = {
  client_id: '',
  integration_id: '',
  client_order_id: '',
}

export const RESTRICTED_EXTERNAL_STATUSES = {
  RECEIVED: 1,
  PICKING: 4,
  WAIT_PACKING: 5,
  PACKING: 6,
  PACKED: 8,
  WAIT_PICKUP: 9,
  PARTIAL_SHIPPED: 10,
  ON_HOLD: 13,
  RECEIVING: 15,
  ERROR: 17,
}

export const INITIAL_QUICK_BATCH = {
  clients: [],
  warehouse_id: '',
  picking_method_id: '',
  batches_needed: '',
  num_per_batch: '',
  batch_type: 1,
  client_include: '',
  apply_rule: 1,
}

export const INITIAL_ITEM = {
  quantity_ordered: '',
  uom_type_id: '',
  sale_price: 0,
  item_id: '',
  sku: '',
  picking_method_id: '',
  quantity_allocated: 0,
  quantity_backorder: 0,
  quantity_each: 1,
  quantity_packed: 0,
  quantity_picked: 0,
  quantity_processed: 0,
  quantity_reserved: 0,
  quantity_shipped: 0,
}

export const INVOICE_B2B_YPC = {
  client_account_number: '',
  invoice_number: '',
  vendor_name: '',
  tps_amount: '',
  tvq_amount: '',
  ordered_by: '',
  payment_terms: '',
  seller: '',
  invoice_date: '',
  sold_to_company: '',
  sold_to_address1: '',
  sold_to_address2: '',
  sold_to_city: '',
  sold_to_postal_code: '',
  sold_to_country_id: COUNTRIES.CANADA,
  sold_to_province_id: '',
  sold_to_phone_number: '',
  sold_to_email: '',
}

export const INITIAL_ORDER = {
  client_id: '',
  order_number: '',
  order_date: '',
  first_name: '',
  address1: '',
  address2: '',
  apt_num1: '',
  apt_num2: '',
  postal_code: '',
  city: '',
  province_id: '',
  country_id: COUNTRIES.CANADA,
  telephone: '',
  last_name: '',
  company: '',
  email: '',
  reference1: '',
  reference2: '',
  client_po: '',
  label_provided: 0,
  packing_slip_note: '',
  carrier_id: '',
  service_id: '',
  inside_delivery: 0,
  lift_gate_required: 0,
  appointment_required: 0,
  deliver_by_date: '',
  signature_required: 0,
  ambient_control: 0,
  ship_onpty3: 0,
  pty3_account: '',
  note: '',
  custom_reference_no: '',
  handling_priority: '',
  processing_priority: 0,
  order_type_id: ORDER_TYPE.B_TO_C,
  temperature_control: '',
  warehouse_id: '',
  order_items: [],
  picking_method_id: '',
  processing_type_id: '',
  rates_shop: '',
  free_handling: '0',
  free_shipping: '0',
  ...INVOICE_B2B_YPC,
}

export const TRANSLATION_KEYS = {
  PACKING_LIST: "Packing List / Liste D'Envoi",
  PURCHASE_ORDER: 'Purchase Order / Bon de Commande',
  GIFT_MESSAGE: 'Gift message /',
  GIFT_MESSAGE_FR: 'Message cadeau',
  SHIP_TO: 'ship to / expédier à:',
  SOLD_TO: 'sold to / vendu à:',
  PURCHASE_ORDER_NUMBER: 'Purchase order # /',
  PURCHASE_ORDER_NUMBER_FR: '# De Bon de commande',
  CUSTOMER_ORDER: 'Customer order # /',
  CUSTOMER_ORDER_FR: '# De commande du client',
  CUSTOMER_ORDER_DATE: 'Customer order date /',
  CUSTOMER_ORDER_DATE_FR: 'Date de commande client',
  PREFERRED_ARRIVAL_DATE: 'Preferred arrival date /',
  PREFERRED_ARRIVAL_DATE_FR: "Date d'arrivée souhaitée",
  CARRIER: 'Carrier /',
  CARRIER_FR: 'Transporteur',
  COSTCO_ITEM: 'Costco item # /',
  COSTCO_ITEM_FR: "# D'Art Costco",
  VENDOR_REF: 'Vendor ref # /',
  VENDOR_REF_FR: '# De réf fourn',
  QTY: 'Qty /',
  QTY_FR: 'Qté',
}

export const TRANSLATION_QC_KEYS = {
  PACKING_LIST: "Liste D'Envoi / Packing List",
  PURCHASE_ORDER: 'Bon de Commande / Purchase Order',
  GIFT_MESSAGE: 'Message cadeau /',
  GIFT_MESSAGE_FR: 'Gift message',
  SHIP_TO: 'expédier à / ship to:',
  SOLD_TO: 'vendu à / sold to:',
  PURCHASE_ORDER_NUMBER: '# De Bon de commande /',
  PURCHASE_ORDER_NUMBER_FR: 'Purchase order #',
  CUSTOMER_ORDER: '# De commande du client /',
  CUSTOMER_ORDER_FR: 'Customer order #',
  CUSTOMER_ORDER_DATE: 'Date de commande client /',
  CUSTOMER_ORDER_DATE_FR: 'Customer order date',
  PREFERRED_ARRIVAL_DATE: "Date d'arrivée souhaitée /",
  PREFERRED_ARRIVAL_DATE_FR: 'Preferred arrival date',
  CARRIER: 'Transporteur /',
  CARRIER_FR: 'Carrier',
  COSTCO_ITEM: "# D'Art Costco /",
  COSTCO_ITEM_FR: 'Costco item #',
  VENDOR_REF: '# De réf fourn /',
  VENDOR_REF_FR: 'Vendor ref #',
  QTY: 'Qté /',
  QTY_FR: 'Qty',
}

export const INIT_BATCH = {
  order_per_batch: 1,
  picking_method_id: '',
}

export const RESTRICTED_EXTERNAL_COLUMNS = [
  'picking_method_id',
  'processing_type_id',
  'batch_id',
  'warehouse_name',
]
