import {
  DEFAULT_LOCATION_CATEGORIES,
  LOCATION_CATEGORIES,
  QUANTITY_TYPES,
} from 'src/constants/Constants'

export const INITIAL_INVENTORY_SEARCH_PARAMS = {
  item_id: '',
  client_id: '',
  susb_like: '',
  location_id: '',
  warehouse_id: '',
  type: '',
  quantity_type_id: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const INITIAL_TRANSFER_SEARCH = {
  warehouse_id: '',
  location_name: '',
}

export const INITIAL_ADJUST = {
  adjust_type: '',
  quantity: '',
  item_reason_id: '',
  note: '',
}

export const INITIAL_HOLD = {
  quantity: '',
  adjust_type: '',
  item_reason_id: '',
  note: '',
}

export const INITIAL_BULK_STATE = {
  adjust_type: '',
  item_reason_id: '',
  note: '',
  holding_data_action: [],
}

export const INITIAL_RELEASE = {
  quantity: '',
  item_reason_id: '',
  note: '',
}

export const INITIAL_TRANSFER = {
  quantity: '',
  adjust_type: '',
  item_reason_id: '',
  note: '',
}

export const INITIAL_LOCATION_TRANSFER = {
  location_name: '',
  warehouse_id: '',
  warehouse_to_id: '',
}

export const DEFAULT_ERROR = { show: null, message: '' }

export const BULK_ACTION_TYPES = {
  HOLD: 'hold',
  RELEASE: 'release',
}

export const ALLOWED_INVENTORY_ACTIONS = [
  QUANTITY_TYPES.AVAILABLE,
  QUANTITY_TYPES.ON_HOLD,
  QUANTITY_TYPES.DAMAGED,
  QUANTITY_TYPES.QUARANTINED,
  QUANTITY_TYPES.RECEIVING,
  QUANTITY_TYPES.EXPIRED,
  QUANTITY_TYPES.RETURNED,
]

export const ALLOWED_INVENTORY_TRANSFER = [
  QUANTITY_TYPES.AVAILABLE,
  QUANTITY_TYPES.DAMAGED,
  QUANTITY_TYPES.ON_HOLD,
  QUANTITY_TYPES.QUARANTINED,
  QUANTITY_TYPES.RECEIVING,
]

export const ALLOWED_INVENTORY_TRANSFER_SKU2SKU = [
  QUANTITY_TYPES.AVAILABLE,
  QUANTITY_TYPES.DAMAGED,
  QUANTITY_TYPES.ON_HOLD,
  QUANTITY_TYPES.QUARANTINED,
  QUANTITY_TYPES.EXPIRED,
  QUANTITY_TYPES.RETURNED,
]

export const ALLOWED_INVENTORY_ADJUST = [
  QUANTITY_TYPES.ON_HOLD,
  QUANTITY_TYPES.DAMAGED,
  QUANTITY_TYPES.QUARANTINED,
  QUANTITY_TYPES.AVAILABLE,
]

export const ALLOWED_INVENTORY_RELEASE = [
  QUANTITY_TYPES.ON_HOLD,
  QUANTITY_TYPES.DAMAGED,
  QUANTITY_TYPES.QUARANTINED,
]

export const INITIAL_LOCATION_QUERY = {
  location_category_id: [...DEFAULT_LOCATION_CATEGORIES, LOCATION_CATEGORIES.VIRTUAL],
}
