import {
  cibApacheAirflow,
  cilColorFill,
  cilFire,
  cilHealing,
  cilListNumbered,
  cilMedicalCross,
  cilSnowflake,
  cilUserX,
  cilXCircle,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CTooltip } from '@coreui/react-pro'
import * as _ from 'lodash'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React from 'react'
import {
  APPOINTMENT_STATUS,
  BACK_CONFIRMATION,
  BOXES,
  COMMON_STATUS,
  DAYS,
  DESCRIPTION_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  EMPTY_STRING,
  EXTRA,
  INVENTORY_TYPE,
  INVOICE_STATUS,
  JIFFIES,
  JOB_STATUS,
  JOB_TYPES,
  LANGUAGES,
  LOCATION_TYPE,
  LOG_STATUS,
  NUMBER_ZERO,
  ORDER_PACKAGE_STATUS,
  ORDER_STATE,
  ORDER_STATUS,
  PICKING_METHODS,
  PICKING_STEPS,
  PICK_PACK_STEPS,
  PURCHASE_CONDITION_STATUS,
  PURCHASE_ORDER_ITEM_STATUS,
  PURCHASE_ORDER_STATUS,
  REACT_APP_FILE_URL,
  REACT_APP_IMAGE_URL,
  RECEIVING_ITEM_CONDITIONS,
  RECEIVING_STATE,
  RECEIVING_STATUS,
  REGEX_LETTERS,
  REGEX_URL_WITH_PREFIX,
  REPLENISHMENT_STEPS,
  RETURN_ITEM_STATUS,
  RETURN_STATUS,
  SCHEDULE_TYPE,
  SORT_OPTIONS,
  SUPPLY_CATEGORIES,
  TICKET_STATUS,
  TRANSACTION_TYPE,
  VINYLS,
  WEIGHT_CONVERSION_FACTORS_TO_KG,
} from '../constants/Constants'

export const imageUrlResolver = (imageId) => {
  return `${REACT_APP_IMAGE_URL}${imageId}/public`
}

export const fileUrlResolver = (path) => {
  return `${REACT_APP_FILE_URL}${path}`
}

export const getStatusText = (status) => {
  switch (parseInt(status)) {
    case 2:
      return 'Archive'
    case 1:
      return 'Active'
    case 0:
      return 'Disabled'
    default:
      return 'Error'
  }
}

export const getJobTypeColor = (status) => {
  switch (parseInt(status)) {
    case JOB_TYPES.PICK:
      return 'info'
    case JOB_TYPES.PACK:
      return 'primary'
    case JOB_TYPES.REPLENISHMENT:
      return 'warning'
    case JOB_TYPES.PUT_AWAY:
      return 'secondary'
    case JOB_TYPES.MOVE_SHEET:
      return 'indigo'
    default:
      return 'success'
  }
}

export const getJobStatusColor = (status) => {
  switch (parseInt(status)) {
    case JOB_STATUS.OPEN:
      return 'info'
    case JOB_STATUS.IN_PROGRESS:
      return 'warning'
    case JOB_STATUS.COMPLETED:
      return 'success'
    case JOB_STATUS.CANCELLED:
      return 'danger'
    default:
      return 'primary'
  }
}

export const getTicketStatusColor = (status) => {
  switch (parseInt(status)) {
    case TICKET_STATUS.OPEN:
      return 'info'
    case TICKET_STATUS.IN_PROGRESS:
      return 'info'
    case TICKET_STATUS.COMPLETED:
      return 'success'
    case TICKET_STATUS.CANCELLED:
      return 'danger'
    default:
      return 'primary'
  }
}

export const getBadge = (status) => {
  switch (parseInt(status)) {
    case 2:
      return 'warning'
    case 1:
      return 'success'
    case 0:
      return 'secondary'
    default:
      return 'primary'
  }
}

export const getStatusBadge = (status) => {
  switch (parseInt(status)) {
    case 2:
      return 'success'
    case 1:
      return 'warning'
    case 0:
      return 'secondary'
    default:
      return 'primary'
  }
}

export const getItemBadge = (item) => {
  switch (parseInt(item)) {
    case 4:
      return 'success'
    case 3:
      return 'warning'
    case 2:
      return 'secondary'
    case 1:
      return 'primary'
    default:
      return 'success'
  }
}

export const getSupplyItemBadge = (item) => {
  switch (parseInt(item)) {
    case 5:
      return 'info'
    case 4:
      return 'teal'
    case 3:
      return 'indigo'
    case 2:
      return 'pink'
    case 1:
      return 'cyan'
    default:
      return 'info'
  }
}

export const getYesNoText = (status, t) => {
  switch (parseInt(status)) {
    case 1:
      return t('yes')
    case 0:
      return t('no')
    default:
      return t('no')
  }
}

export const getOrderStatusColor = (status) => {
  switch (parseInt(status)) {
    case ORDER_STATUS.RECEIVED:
      return 'info'
    case ORDER_STATUS.RELEASED:
      return 'blue'
    case ORDER_STATUS.BATCHED:
      return 'teal'
    case ORDER_STATUS.PICKING:
      return 'indigo'
    case ORDER_STATUS.WAIT_PACKING:
      return 'sky'
    case ORDER_STATUS.PACKING:
      return 'dark'
    case ORDER_STATUS.PACKED:
      return 'purple'
    case ORDER_STATUS.PARTIAL:
      return 'orange'
    case ORDER_STATUS.WAIT_PICKUP:
      return 'primary'
    case ORDER_STATUS.PARTIAL_SHIPPED:
      return 'warning'
    case ORDER_STATUS.SHIPPED:
      return 'success'
    case ORDER_STATUS.BACKORDER:
      return 'yellow'
    case ORDER_STATUS.ON_HOLD:
      return 'cyan'
    case ORDER_STATUS.CANCELLED:
      return 'danger'
    case ORDER_STATUS.RECEIVING:
      return 'maroon'
    case ORDER_STATUS.AWAITING_SHIPPING:
      return 'secondary'
    case ORDER_STATUS.ERROR:
      return 'rose'
    default:
      return 'dark'
  }
}

export const getOrderStateColor = (state) => {
  switch (parseInt(state)) {
    case ORDER_STATE.CLOSE:
      return 'danger'
    case ORDER_STATE.OPEN:
      return 'success'
    case ORDER_STATE.ON_HOLD:
      return 'orange'
    default:
      return 'dark'
  }
}

export const getOrderStateDescription = (state, t) => {
  switch (parseInt(state)) {
    case ORDER_STATE.CLOSE:
      return t('closed')
    case ORDER_STATE.OPEN:
      return t('open')
    case ORDER_STATE.ON_HOLD:
      return t('on-hold')
    default:
      return t('n-a')
  }
}

export const getPoStatusColor = (status) => {
  switch (parseInt(status)) {
    case PURCHASE_ORDER_STATUS.CLOSED:
      return 'secondary'
    case PURCHASE_ORDER_STATUS.OPEN:
      return 'success'
    case PURCHASE_ORDER_STATUS.PARTIAL:
      return 'info'
    case PURCHASE_ORDER_STATUS.CANCELLED:
      return 'danger'
    default:
      return 'dark'
  }
}

export const getPoConditionColor = (status) => {
  switch (parseInt(status)) {
    case PURCHASE_CONDITION_STATUS.PENDING:
      return 'secondary'
    case PURCHASE_CONDITION_STATUS.COMPLETE:
      return 'success'
    case PURCHASE_CONDITION_STATUS.PARTIAL:
      return 'info'
    case PURCHASE_CONDITION_STATUS.CANCELLED:
      return 'danger'
    case PURCHASE_CONDITION_STATUS.OVER:
      return 'warning'
    default:
      return 'dark'
  }
}

export const getPoItemStatusColor = (status) => {
  switch (parseInt(status)) {
    case PURCHASE_ORDER_ITEM_STATUS.PENDING:
      return 'secondary'
    case PURCHASE_ORDER_ITEM_STATUS.COMPLETE:
      return 'success'
    case PURCHASE_ORDER_ITEM_STATUS.PARTIAL:
      return 'info'
    case PURCHASE_ORDER_ITEM_STATUS.CANCELLED:
      return 'danger'
    case PURCHASE_ORDER_ITEM_STATUS.OVER:
      return 'warning'
    default:
      return 'dark'
  }
}

export const getReceivingStatusColor = (status) => {
  switch (parseInt(status)) {
    case RECEIVING_STATUS.IN_PROGRESS:
      return 'info'
    case RECEIVING_STATUS.ON_HOLD:
      return 'indigo'
    case RECEIVING_STATUS.PENDING:
      return 'warning'
    case RECEIVING_STATUS.FINALIZED:
      return 'success'
    case RECEIVING_STATUS.VERIFICATION:
      return 'rose'
    case RECEIVING_STATUS.ERROR:
      return 'dm3red'
    case RECEIVING_STATUS.CANCELED:
      return 'purple'
    case RECEIVING_STATUS.FINALIZING:
      return 'lime'
    default:
      return null
  }
}

export const getReceivingStateColor = (state) => {
  switch (parseInt(state)) {
    case RECEIVING_STATE.CLOSE:
      return 'danger'
    case RECEIVING_STATE.OPEN:
      return 'success'
    case RECEIVING_STATE.RELEASED_APPROVED:
      return 'info'
    case RECEIVING_STATE.PENDING_CLIENT_RELEASE:
      return 'warning'
    default:
      return 'dark'
  }
}

export const getReceivingStateDescription = (state, t) => {
  switch (parseInt(state)) {
    case RECEIVING_STATE.CLOSE:
      return t('closed')
    case RECEIVING_STATE.OPEN:
      return t('open')
    case RECEIVING_STATE.RELEASED_APPROVED:
      return t('released-approved')
    case RECEIVING_STATE.PENDING_CLIENT_RELEASE:
      return t('pending-client-release')
    default:
      return t('n-a')
  }
}

export const getReceivingConditionColor = (status) => {
  switch (parseInt(status)) {
    case RECEIVING_ITEM_CONDITIONS.PHYSICAL_PRODUCT_IS_DAMAGED:
      return 'info'
    case RECEIVING_ITEM_CONDITIONS.MISSING_PIECE:
      return 'danger'
    case RECEIVING_ITEM_CONDITIONS.DAMAGE_TO_PACKAGING:
      return 'warning'
    case RECEIVING_ITEM_CONDITIONS.NOT_IN_ORIGINAL_PACKAGING:
      return 'success'
    case RECEIVING_ITEM_CONDITIONS.PRODUCT_IS_BROKEN_OR_DEFECTIVE:
      return 'pink'
    case RECEIVING_ITEM_CONDITIONS.OTHER:
      return 'teal'
    case RECEIVING_ITEM_CONDITIONS.RETENTION:
      return 'indigo'
    case RECEIVING_ITEM_CONDITIONS.PENDING_CLIENT:
      return 'orange'
    case RECEIVING_ITEM_CONDITIONS.QA_APPROVED:
      return 'secondary'
    case RECEIVING_ITEM_CONDITIONS.QA_RELISE:
      return 'sky'
    default:
      return 'dark'
  }
}

export const getStatus = (status) => {
  switch (parseInt(status)) {
    case 1:
      return 'dark'
    case 2:
      return 'info'
    case 3:
      return 'warning'
    case 4:
      return 'primary'
    case 5:
      return 'success'
    case 6:
      return 'danger'
    default:
      return 'indigo'
  }
}

export const formatDateWithSlash = (date) => {
  if (!date) {
    return ''
  }
  return new Date(date.replace(/-/g, '/'))
}

export const formatDate = (date) => {
  if (!date) {
    return ''
  }
  return moment(date).format('YYYY/MM/DD')
}

export const formatDateWithMonth = (date) => {
  if (!date) {
    return ''
  }
  return moment(date).format('DD-MMM-YYYY')
}

export const formatDateTime = (date) => {
  if (!date) {
    return ''
  }
  return moment(date).format('YYYY/MM/DD HH:mm')
}

export const formatTime = (date) => {
  if (!date) {
    return ''
  }
  return moment(date).format('HH:mm')
}

export const formatDateTimeWithDashes = (date) => {
  if (!date) {
    return EMPTY_STRING
  }
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export const formatDateWithDashes = (date) => {
  if (!date) {
    return EMPTY_STRING
  }
  return moment(date).format('YYYY-MM-DD')
}

export const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const validatePhone = (phone) => {
  return /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(
    phone,
  )
}

export const validateURL = (url) => {
  return REGEX_URL_WITH_PREFIX.test(url)
}

export const currentDateTime = moment().format('YYYY-MM-DD HH:mm')
export const currentDate = moment().format('YYYY-MM-DD')
export const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
export const tomorrowDate = moment().add(1, 'days').format('YYYY-MM-DD')
export const currentTime = moment().format('HH:mm')

export const calculateAvailableQuantity = (item) => {
  let quantity = item.quantity - item.reserved - item.allocated - item.receiving
  return quantity < 0 ? 0 : quantity
}

export const formatCurrency = (value) => {
  const numericValue = parseFloat(value)
  if (isNaN(numericValue) || value instanceof Date) return 0
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericValue)
}

export const formatQuantity = (value, minFractionDigits = 2, maxFractionDigits = 2) => {
  const numericValue = parseFloat(value)
  if (isNaN(numericValue) || value instanceof Date) return 0
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(numericValue)
}

export const groupBy = (array, key) => {
  if (!Array.isArray(array) || array.length === 0) {
    return {}
  }
  return array.reduce((grouped, item) => {
    const keyValue = item[key]
    if (keyValue !== undefined && keyValue !== null) {
      grouped[keyValue] = grouped[keyValue] || []
      grouped[keyValue].push(item)
    }
    return grouped
  }, {})
}

export const getDescriptionName = (object, id, languageId) => {
  if (object instanceof Array && object.length > 0) {
    const descriptions = object.find((o) => o.id === parseInt(id))?.descriptions
    if (descriptions instanceof Array && descriptions.length > 0) {
      return descriptions && descriptions.find((d) => d.language_id === parseInt(languageId))?.name
    }
  }
  return ''
}

export const getGenericDescriptionText = (object, id, languageId) => {
  if (object instanceof Array && object.length > 0) {
    const descriptions = object.find((o) => o.id === parseInt(id))?.descriptions
    return (
      descriptions && descriptions.find((d) => d.language_id === parseInt(languageId))?.description
    )
  } else {
    return ''
  }
}

export const getDescriptionText = (object, languageId, maxLength = DESCRIPTION_MAX_LENGTH) => {
  if (object && object.descriptions instanceof Array && object.descriptions.length > 0) {
    const descriptions = object.descriptions
    const description =
      descriptions.find((d) => d.language_id === parseInt(languageId))?.description || ''
    return description?.length > maxLength
      ? description?.substring(0, maxLength) + '...'
      : description
  } else {
    return ''
  }
}

export const getGenericDescriptionName = (object, languageId) => {
  if (object && object.descriptions instanceof Array && object.descriptions.length > 0) {
    const descriptions = object.descriptions
    return descriptions && descriptions.find((d) => d.language_id === parseInt(languageId))?.name
  } else {
    return ''
  }
}

export const getLangName = (object, languageId) => {
  if (object && object.descriptions instanceof Array && object.descriptions.length > 0) {
    const descriptions = object.descriptions
    return descriptions && descriptions.find((d) => d.language_id === parseInt(languageId))?.name
  } else {
    return ''
  }
}

export const getDescriptionOptions = (object, languageId, key = 'd') => {
  if (object instanceof Array && object.length > 0) {
    return (
      object &&
      object.map((o, i) => (
        <option key={`${key}-${i}`} value={o.id}>
          {o?.descriptions?.find((d) => d.language_id === parseInt(languageId))?.name || ''}
        </option>
      ))
    )
  } else {
    return ''
  }
}

export const getDescriptionColorOptions = (object, languageId, key = 'd') => {
  if (object instanceof Array && object.length > 0) {
    return (
      object &&
      object.map((o, i) => (
        <option
          className={`text-${getReceivingStatusColor(o.id)}`}
          key={`${key}-${i}`}
          value={o.id}
        >
          {o?.descriptions?.find((d) => d.language_id === parseInt(languageId))?.name || ''}
        </option>
      ))
    )
  } else {
    return ''
  }
}

export const getBothLangDescriptionText = (object) => {
  if (object && object.descriptions instanceof Array && object.descriptions.length > 0) {
    const descriptions = object.descriptions
    return (
      descriptions &&
      descriptions.find((d) => d.language_id === parseInt(LANGUAGES.ENGLISH))?.description +
        ' (' +
        descriptions.find((d) => d.language_id === parseInt(LANGUAGES.FRENCH))?.description +
        ')'
    )
  } else {
    return EMPTY_STRING
  }
}

export const initialDescriptions = [
  {
    name: '',
    language_id: 1,
  },
  {
    name: '',
    language_id: 2,
  },
]

export const getReturnItemStatusColor = (status) => {
  switch (parseInt(status)) {
    case RETURN_ITEM_STATUS.PENDING_3DM:
      return 'info'
    case RETURN_ITEM_STATUS.CLOSED:
      return 'danger'
    case RETURN_ITEM_STATUS.OPEN:
      return 'success'
    case RETURN_ITEM_STATUS.CLIENT_APPROVED:
      return 'primary'
    case RETURN_ITEM_STATUS.PENDING_CLIENT:
      return 'warning'
    default:
      return 'dark'
  }
}

export const getReturnStatusColor = (status) => {
  switch (parseInt(status)) {
    case RETURN_STATUS.PENDING_3DM:
      return 'success'
    case RETURN_STATUS.CLOSED:
      return 'danger'
    case RETURN_STATUS.PENDING_CLIENT:
      return 'info'
    default:
      return 'dark'
  }
}

export const trimmedInput = (value) => {
  if (!value) return ''
  return value?.toString()?.trim()?.toUpperCase()
}

export const getPlaceHolder = (currentStep, currentTote, t) => {
  switch (currentStep) {
    case PICKING_STEPS.STEP_TICKET:
      return t('scan-pick-ticket')
    case PICKING_STEPS.STEP_LOCATION:
      return t('scan-pick-location')
    case PICKING_STEPS.STEP_ITEM:
      return t('scan-pick-item')
    case PICKING_STEPS.STEP_TOTE:
      return currentTote ? t('scan-tote') + currentTote : t('scan-pick-tote')
    case PICKING_STEPS.STEP_EMPTY:
      return ''
    default:
      return t('scan-pick-ticket')
  }
}

export const getPlaceHolderForBatchPicking = (currentStep, t) => {
  switch (currentStep) {
    case PICKING_STEPS.STEP_TICKET:
      return t('scan-pick-ticket')
    case PICKING_STEPS.STEP_LOCATION:
      return t('scan-pick-location')
    case PICKING_STEPS.STEP_ITEM:
      return t('scan-pick-item')
    case PICKING_STEPS.STEP_TOTE:
      return t('scan-any-tote')
    case PICKING_STEPS.STEP_EMPTY:
      return ''
    default:
      return t('scan-pick-ticket')
  }
}

export const getPlaceHolderForReplenishment = (currentStep, currentTote, t) => {
  switch (currentStep) {
    case REPLENISHMENT_STEPS.STEP_TICKET:
      return t('scan-replenishment-ticket')
    case REPLENISHMENT_STEPS.STEP_LOCATION:
      return t('scan-replenishment-location')
    case REPLENISHMENT_STEPS.STEP_ITEM:
      return t('scan-replenishment-item')
    case REPLENISHMENT_STEPS.STEP_TRANSFER_LOCATION:
      return t('scan-replenishment-transfer-location')
    case REPLENISHMENT_STEPS.STEP_TOTE:
      return t('scan-replenishment-tote')
    // return currentTote ? t('scan-tote') + currentTote : t('scan-replenishment-tote')
    case REPLENISHMENT_STEPS.STEP_TRANSFER_LOCATION_2:
      return t('scan-replenishment-transfer-location')
    case REPLENISHMENT_STEPS.STEP_QP_LOCATION:
      return t('scan-qp-location')
    case REPLENISHMENT_STEPS.STEP_EMPTY:
      return EMPTY_STRING
    default:
      return t('scan-pick-ticket')
  }
}

export const presentAttributes = (item, t) => {
  return (
    <>
      {item?.ambient === 1 && (
        <CTooltip content={t('ambient')} placement="top">
          <CIcon icon={cibApacheAirflow} className="me-2" />
        </CTooltip>
      )}
      {item?.cold_chain === 1 && (
        <CTooltip content={t('cold_chain')} placement="top">
          <CIcon icon={cilSnowflake} className="me-2" />
        </CTooltip>
      )}
      {item?.hazmat === 1 && (
        <CTooltip content={t('hazmat')} placement="top">
          <CIcon icon={cilFire} className="me-2" />
        </CTooltip>
      )}
      {item?.controlled === 1 && (
        <CTooltip content={t('controlled')} placement="top">
          <CIcon icon={cilXCircle} className="me-2" />
        </CTooltip>
      )}
      {item?.restricted_access === 1 && (
        <CTooltip content={t('restricted_access')} placement="top">
          <CIcon icon={cilUserX} className="me-2" />
        </CTooltip>
      )}
      {item?.restricted_pharma === 1 && (
        <CTooltip content={t('restricted_pharma')} placement="top">
          <CIcon icon={cilMedicalCross} className="me-2" />
        </CTooltip>
      )}
      {item?.restricted_ecomm === 1 && (
        <CTooltip content={t('restricted_ecomm')} placement="top">
          <CIcon icon={cilHealing} className="me-2" />
        </CTooltip>
      )}
      {item?.restricted_precursor === 1 && (
        <CTooltip content={t('restricted_precursor')} placement="top">
          <CIcon icon={cilColorFill} className="me-2" />
        </CTooltip>
      )}
      {item?.serial_number === 1 && (
        <CTooltip content={t('serial_number')} placement="top">
          <CIcon icon={cilListNumbered} className="me-2" />
        </CTooltip>
      )}
    </>
  )
}

export const getAttributeIcon = (attribute) => {
  switch (attribute) {
    case 'ambient':
      return <CIcon icon={cibApacheAirflow} className="me-2" />
    case 'cold_chain':
      return <CIcon icon={cilSnowflake} className="me-2" />
    case 'hazmat':
      return <CIcon icon={cilFire} className="me-2" />
    case 'controlled':
      return <CIcon icon={cilXCircle} className="me-2" />
    case 'restricted_access':
      return <CIcon icon={cilUserX} className="me-2" />
    case 'restricted_pharma':
      return <CIcon icon={cilMedicalCross} className="me-2" />
    case 'restricted_ecomm':
      return <CIcon icon={cilHealing} className="me-2" />
    case 'restricted_precursor':
      return <CIcon icon={cilColorFill} className="me-2" />
    case 'serial_number':
      return <CIcon icon={cilListNumbered} className="me-2" />
    default:
      return null
  }
}

export const getAppointmentStatusColor = (status) => {
  switch (parseInt(status)) {
    case APPOINTMENT_STATUS.OPEN:
      return 'status-open'
    case APPOINTMENT_STATUS.DELIVERED:
      return 'status-delivered'
    case APPOINTMENT_STATUS.UNSCHEDULED:
      return 'status-unscheduled'
    case APPOINTMENT_STATUS.REFUSED:
      return 'status-refused'
    case APPOINTMENT_STATUS.LATE:
      return 'status-lated'
    case APPOINTMENT_STATUS.CANCELLED:
      return 'status-cancelled'
    case APPOINTMENT_STATUS.MISSED:
      return 'status-missed'
    case APPOINTMENT_STATUS.RECEIVING:
      return 'status-receiving'
    case APPOINTMENT_STATUS.COMPLETED:
      return 'status-completed'
    default:
      return 'primary'
  }
}

export const getTypeLocationText = (type, t) => {
  switch (parseInt(type)) {
    case LOCATION_TYPE.TEMPORARY:
      return t('temporary')
    case LOCATION_TYPE.PERMANENT:
      return 'permanent'
    default:
      return 'temporary'
  }
}

export const dateDiffMinutes = (date2, date1) => {
  let diff = (date2.getTime() - date1.getTime()) / 1000
  diff /= 60
  return Math.round(diff)
}

export const dateDiffDays = (date2, date1) => {
  const firstDate = new Date(date1)
  const secondDate = new Date(date2)
  if (!(firstDate instanceof Date) || !(secondDate instanceof Date)) return 0
  const diffMilliseconds = secondDate.getTime() - firstDate.getTime()
  if (diffMilliseconds < 0) return 0
  return Math.round(diffMilliseconds / (1000 * 60 * 60 * 24))
}

export const getOrderPackageStatus = (status) => {
  switch (parseInt(status)) {
    case ORDER_PACKAGE_STATUS.OPEN:
      return 'info'
    case ORDER_PACKAGE_STATUS.UNDER_PACKING:
      return 'dark'
    case ORDER_PACKAGE_STATUS.PENDING:
      return 'warning'
    case ORDER_PACKAGE_STATUS.PACKED:
      return 'primary'
    case ORDER_PACKAGE_STATUS.SHIPPED:
      return 'success'
    default:
      return 'secondary'
  }
}

export const formatMeasurement = (value) => {
  const numericValue = parseFloat(value)
  if (isNaN(numericValue) || value instanceof Date) return 0
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(numericValue)
}

export const formatTwoDigits = (text) => {
  if (!text) return ''
  return Math.round((Number(text) + Number.EPSILON) * 100) / 100
}

export const handleError = (error, languageId = LANGUAGES.ENGLISH) => {
  if (!error) return 'Server Error'
  if (typeof error === 'object') {
    const description = error?.descriptions?.some((d) => +d?.language_id === +languageId)?.name
    if (description) return description
    if (error?.[0]?.descriptions?.[0]?.name)
      return error
        .map((item) => item?.descriptions?.find((d) => +d?.language_id === +languageId)?.name)
        .join(', ')
    if (error?.[0]?.message) return error[0].message
    if (error?.message) return error.message
    return 'Server Error'
  }
  return 'Server Error'
}

export const handleErrorMessages = (errorData) => {
  let errors = null
  if (errorData) {
    if (typeof errorData === 'object') {
      const newError = [...Object.values(errorData)]
      errors = newError
    } else {
      errors = errorData
    }
  }
  return errors.map((error, index) => (
    <span key={index}>
      {handleError(error)} <br />
    </span>
  ))
}

export const isErrorIncludes = (errorData, field) => {
  let errors = null
  if (errorData) {
    if (typeof errorData === 'object') {
      const newError = [...Object.values(errorData)]
      errors = newError
    } else {
      errors = errorData
    }
  }
  return errors
    ? errors.filter((error) => error?.toUpperCase()?.includes(field.toUpperCase()))?.length > 0
    : false
}

export const stringToArray = (str) => {
  if (typeof str === 'string' && str.length > 0) {
    if (str.includes('SQL')) {
      return [['SQL', str]]
    } else {
      const parsedData = JSON.parse(str)
      const properties = Object.entries(parsedData)
      return properties
    }
  } else {
    return []
  }
}

export const objectToArray = (obj) => {
  if (obj) {
    if (typeof obj === 'object') {
      const newArray = [...Object.values(obj)]
      return newArray
    } else {
      return obj
    }
  }
}

export const getLogStatusBadge = (status) => {
  if (typeof status === 'string') {
    const statusString = status.substring(1, status.length - 1)
    switch (statusString) {
      case LOG_STATUS.SUCCESS:
        return 'success'
      case LOG_STATUS.ERROR:
        return 'dm3red'
      default:
        return 'dark'
    }
  } else {
    return 'dark'
  }
}

export const getLogBadge = (status) => {
  switch (+status) {
    case 1:
      return 'success'
    case 0:
      return 'dark'
    default:
      return 'dark'
  }
}

export const getPlaceHolderForPickingPacking = (currentStep, t) => {
  switch (currentStep) {
    case PICK_PACK_STEPS.STEP_TICKET:
      return t('scan-pick-pack-ticket')
    case PICK_PACK_STEPS.STEP_ORDERS:
      return t('scan-pick-pack-order')
    case PICK_PACK_STEPS.STEP_ITEM:
      return t('scan-pick-pack-item')
    case PICK_PACK_STEPS.STEP_LOCATION:
      return t('scan-pick-pack-location')
    case PICK_PACK_STEPS.STEP_SUPPLY_ZONE:
      return t('scan-pick-pack-supply-zone')
    case PICK_PACK_STEPS.STEP_SCAN_SUPPLY:
      return t('scan-pick-pack-supply')
    case PICK_PACK_STEPS.STEP_EMPTY:
      return EMPTY_STRING
    default:
      return t('scan-pick-pack-ticket')
  }
}

export const addLeadingZeros = (input, numberOfZeros) => {
  if (!input) {
    return ''
  }

  const spliceCount = parseInt(numberOfZeros)
  input = input.toString()
  if (numberOfZeros > NUMBER_ZERO && input.length < numberOfZeros) {
    while (numberOfZeros > NUMBER_ZERO) {
      input = NUMBER_ZERO + input
      numberOfZeros--
    }

    return input.slice(-spliceCount)
  }

  return input
}

export const getSupplyTypeDescription = (supplyType, t) => {
  switch (supplyType) {
    case SUPPLY_CATEGORIES.BOX:
      return t(BOXES)
    case SUPPLY_CATEGORIES.JIFFY:
      return t(JIFFIES)
    case SUPPLY_CATEGORIES.EXTRA:
      return t(EXTRA)
    case SUPPLY_CATEGORIES.VINYLS:
      return t(VINYLS)
    default:
      return t(BOXES)
  }
}

export const sortArray = (inputArray, keyName, order = SORT_OPTIONS.ASC) => {
  if (!Array.isArray(inputArray) || inputArray.length === 0) return []

  return [...inputArray].sort((a, b) => {
    const comparison = a[keyName].localeCompare(b[keyName])
    return order === SORT_OPTIONS.DESC ? -comparison : comparison
  })
}

export const customArraySort = (inputArray, keyName, keyType) => {
  if (!Array.isArray(inputArray) || inputArray.length === 0) return []
  const arrayCopy = [...inputArray]
  return arrayCopy.sort((a, b) => {
    if ((!a[keyType] || a[keyType] === 0) && (!b[keyType] || b[keyType] === 0)) {
      return a[keyName].localeCompare(b[keyName])
    } else if (!a[keyType] || a[keyType] === 0) {
      return -1
    } else if (!b[keyType] || b[keyType] === 0) {
      return 1
    } else if (a[keyType] === b[keyType]) {
      return a[keyName].localeCompare(b[keyName])
    } else {
      return a[keyType] - b[keyType]
    }
  })
}

export const getIntervalOptionsByScheduleType = (scheduleType) => {
  switch (scheduleType) {
    case SCHEDULE_TYPE.MINUTES:
      return [...Array(60)].map((_, index) => {
        return {
          text: index + 1 + ' Minutes',
          value: index + 1,
        }
      })
    case SCHEDULE_TYPE.HOURLY:
      return [...Array(24)].map((_, index) => {
        return {
          text: index + 1 + ' Hours',
          value: index + 1,
        }
      })
    case SCHEDULE_TYPE.DAILY:
      return [...Array(30)].map((_, index) => {
        return {
          text: index + 1 + ' Days',
          value: index + 1,
        }
      })
    case SCHEDULE_TYPE.CUSTOM:
      return Object.values(DAYS).map((day) => {
        return {
          text: day,
          value: day,
        }
      })
    default:
      return [...Array(60)].map((_, index) => {
        return {
          text: index + 1 + ' Minutes',
          value: index + 1,
        }
      })
  }
}

export const handleFloatPressDown = (event) => {
  if (event.key === '+' || event.key === '-' || event.key === 'e') {
    event.preventDefault()
  }
}

export const handleIntegerPressDown = (event) => {
  if (
    event.key === '+' ||
    event.key === '-' ||
    event.key === '.' ||
    event.key === ',' ||
    event.key === 'e'
  ) {
    event.preventDefault()
  }
}

export const handleTextPressDown = (event) => {
  if (!REGEX_LETTERS.test(event.key)) {
    event.preventDefault()
  }
}

export const getCountriesOptionForMultiSelect = (countries, languageId, selectedCountries) => {
  if (countries && countries instanceof Array) {
    return countries
      .filter((c) => c?.descriptions?.find((d) => d.language_id === parseInt(languageId))?.name)
      .map((c) => {
        return {
          text:
            c?.descriptions?.find((d) => d.language_id === parseInt(languageId))?.name ||
            EMPTY_STRING,
          value: c.iso_code_2,
          selected: selectedCountries?.includes(c.iso_code_2) ?? false,
        }
      })
  }

  return []
}

export const getIntegrationStatusText = (status) => {
  switch (parseInt(status)) {
    case 0:
      return 'start'
    case 1:
      return 'in-process'
    case 2:
      return 'complete'
    default:
      return 'error'
  }
}

export const dateDiffHHMMSS = (date2, date1) => {
  let diff = date2.getTime() - date1.getTime()
  let seconds = diff / 1000
  const hours = Math.round(seconds / 3600)
  seconds = seconds % 3600
  const minutes = Math.round(seconds / 60)
  seconds = seconds % 60

  return (
    (addLeadingZeros(hours, 2) || '00') +
    ':' +
    (addLeadingZeros(minutes, 2) || '00') +
    ':' +
    (addLeadingZeros(seconds, 2) || '00')
  )
}

export const timeToInteger = (time) => {
  if (time) {
    return new Date(time)?.getTime()
  }
  return 0
}

export const multiGroupBy = (arr, keys) => {
  if (!keys.length) return arr
  var first = keys[0]
  var rest = keys.slice(1)
  return _.mapValues(_.groupBy(arr, first), (value) => {
    return multiGroupBy(value, rest)
  })
}

export const getPickingMethodBadge = (method) => {
  switch (parseInt(method)) {
    case PICKING_METHODS.BATCH_PICK:
      return 'secondary'
    case PICKING_METHODS.ORDER_PICK:
      return 'success'
    case PICKING_METHODS.PICK_PACK:
      return 'info'
    default:
      return 'primary'
  }
}

export const getLocationTypeText = (type, zone) => {
  if (parseInt(type) === INVENTORY_TYPE.QUICK_PICK) {
    return 'Q'
  } else if (parseInt(type) === INVENTORY_TYPE.LOADING_LOCATION) {
    return 'L'
  } else {
    if (zone && zone === 1) {
      return 'Z'
    }
  }

  return ''
}

export const getLocationTypeBadge = (type, zone) => {
  if (parseInt(type) === INVENTORY_TYPE.QUICK_PICK) {
    return 'info'
  } else if (parseInt(type) === INVENTORY_TYPE.LOADING_LOCATION) {
    return 'success'
  } else {
    if (zone && zone === 1) {
      return 'primary'
    }
  }

  return ''
}

export const generateRandomDigits = (totalDigits) => {
  if (totalDigits && totalDigits > 0) {
    const zero = '0'
    const num1 = 9 + zero.repeat(totalDigits - 1)
    const num2 = 1 + zero.repeat(totalDigits - 1)
    let randomNum = Math.floor(Math.random() * num1 + num2)

    while (randomNum.toString().length !== totalDigits) {
      randomNum = Math.floor(Math.random() * num1 + num2)
    }

    return randomNum
  }

  return 0
}

export const filterWeekends = (date) => {
  const day = date.getDay()
  return day !== 0 && day !== 6
}

export const onClickBack = (isUpdated, showSweetAlert, navigate, t, navigatePath) => {
  isUpdated
    ? showSweetAlert({
        message: t(BACK_CONFIRMATION),
        onConfirm: () => navigate(navigatePath),
      })
    : navigate(navigatePath)
}

export const getDescriptionList = (object, languageId) => {
  if (object instanceof Array && object.length) {
    const descriptionList = object.reduce((acc, description, index) => {
      const separator = index === object.length - 1 ? '' : ', '
      acc += `${description.id} = ${getGenericDescriptionName(description, languageId)}${separator}`
      return acc
    }, '')
    return descriptionList
  } else {
    return ''
  }
}

/**
 * @param {Object} obj1 - Old object value
 * @param {Object} obj2 - New object value
 * @returns {Object} Object contains difference between New object and Old object.
 */
export const diff = (obj1, obj2) => {
  if (!obj2 || typeof obj2 !== 'object') {
    return obj1
  }

  var diffs = {}

  function arraysMatch(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }

    return true
  }

  function compare(item1, item2, key) {
    var type1 = Object.prototype.toString.call(item1)
    var type2 = Object.prototype.toString.call(item2)

    if (type2 === '[object Undefined]') {
      diffs[key] = null
      return
    }

    if (type1 !== type2) {
      diffs[key] = item2
      return
    }

    if (type1 === '[object Object]') {
      var objDiff = diff(item1, item2)
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff
      }
      return
    }

    if (type1 === '[object Array]' && !arraysMatch(item1, item2)) {
      diffs[key] = item2
      return
    }

    if (type1 === '[object Function]' && item1.toString() !== item2.toString()) {
      diffs[key] = item2
    } else if (type1 !== '[object Array]' && item1 !== item2) {
      diffs[key] = item2
    }
  }

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      compare(obj1[key], obj2[key], key)
    }
  }

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key) && !obj1[key] && obj1[key] !== obj2[key]) {
      diffs[key] = obj2[key]
    }
  }

  return diffs
}

export const getCarrierNameByCode = (object, carrier_code, languageId) => {
  if (object instanceof Array && object.length > 0) {
    const descriptions = object.find((o) => o.carrier_code === carrier_code)?.descriptions
    if (descriptions instanceof Array && descriptions.length > 0) {
      return descriptions && descriptions.find((d) => d.language_id === parseInt(languageId))?.name
    }
  }
  return ''
}

export const isNonEmptyArray = (array) => Array.isArray(array) && array.length > 0

export const isNotEmptyString = (value) => {
  return typeof value === 'string' && value.trim().length > 0
}

export const getCharASCII = (text, index = 0) => {
  return !!text ? text.charCodeAt(index) : 0
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const calculateAvailability = (itemsAvailable, itemPerUnit) => {
  let units = Math.floor(+itemsAvailable / +itemPerUnit)

  return {
    units: units || 0,
    eachUnits: +itemsAvailable % +itemPerUnit || 0,
  }
}

export const proxEmailLengthCheck = (email) => {
  if (email && email.length > 0) {
    const atIndex = email.indexOf('@')
    return atIndex !== -1 && atIndex < 15 ? false : true
  }
}

export const calculateBaseUomQuantity = (item, quantity) => {
  const itemQuantity = +item[quantity]
  if (item?.item?.measurements?.length > 0 && itemQuantity > 0) {
    const baseQuantity = item.item.measurements?.find(
      (element) => +element.uom_type_id === +item.item.base_uom_type_id,
    )?.quantity
    const uomQuantity = item.item.measurements?.find(
      (element) => +element.uom_type_id === +item.uom_type_id,
    )?.quantity
    return uomQuantity ? (itemQuantity * +uomQuantity) / +baseQuantity : 0
  }
  return 0
}

export const getBaseUomQuantity = (item) => {
  if (isEmpty(item)) return 1
  return (
    Number(
      item?.measurements?.find((i) => +item?.base_uom_type_id === +i?.uom_type_id)?.quantity,
    ) || 1
  )
}

export const calculateBaseUomFromEachQuantity = (item, quantity) => {
  const itemQuantity = +item[quantity]
  if (item?.item?.measurements?.length > 0 && itemQuantity > 0) {
    const baseQuantity = item.item.measurements?.find(
      (element) => +element.uom_type_id === +item.item.base_uom_type_id,
    )?.quantity
    return itemQuantity && baseQuantity ? itemQuantity / +baseQuantity : 0
  }
  return 0
}

export const getBaseUomList = (uomTypes, item) => {
  if (isEmpty(uomTypes) || isEmpty(item)) return []
  const baseHierarchy = uomTypes?.find((uom) => +uom?.id === +item?.base_uom_type_id)?.hierarchy
  return uomTypes
    .filter(
      (type) =>
        item?.measurements?.some((item) => type.id === item.uom_type_id) &&
        type.hierarchy >= baseHierarchy,
    )
    .sort((a, b) => a.sort_order - b.sort_order)
}

export const getInvoiceStatusColor = (status) => {
  switch (parseInt(status)) {
    case INVOICE_STATUS.PENDING_REVIEW:
      return 'info'
    case INVOICE_STATUS.REVIEW_IN_PROGRESS:
      return 'orange'
    case INVOICE_STATUS.APPROVED:
      return 'teal'
    case INVOICE_STATUS.UNPAID:
      return 'red'
    case INVOICE_STATUS.PARTIALLY_PAID:
      return 'pink'
    case INVOICE_STATUS.PAID:
      return 'emerald'
    case INVOICE_STATUS.OVERDUE:
      return 'purple'
    case INVOICE_STATUS.CANCELLED:
      return 'pink'
    default:
      return 'maroon'
  }
}

export const removeEmptyObjectValues = (object) => {
  if (typeof object !== 'object') {
    return object
  }
  const filteredEntries = Object.entries(object).filter(([, value]) => value)
  return Object.fromEntries(filteredEntries)
}

export const fileDownload = (data, filename) => {
  if (isEmpty(data)) return null
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getDescriptionTooltip = (element, t, length = DESCRIPTION_LENGTH) => {
  if (isEmpty(element)) return <td>{t('n-a')}</td>
  return (
    <CTooltip
      className={element?.length < length ? 'd-none' : 'tooltip bs-tooltip-top fade show'}
      content={element}
      placement="top"
    >
      <td>{element?.length > length ? element.substring(0, length) + '...' : element || ''}</td>
    </CTooltip>
  )
}

export const getTransactionTypeColor = (status) => {
  switch (parseInt(status)) {
    case TRANSACTION_TYPE.RECEIVING:
      return 'info'
    case TRANSACTION_TYPE.CREATE_INVENTORY:
      return 'blue'
    case TRANSACTION_TYPE.ADJUSTMENT:
      return 'teal'
    case TRANSACTION_TYPE.SKU_SKU_TRANSFER:
      return 'indigo'
    case TRANSACTION_TYPE.TRANSFER:
      return 'pink'
    case TRANSACTION_TYPE.RETURN:
      return 'emerald'
    case TRANSACTION_TYPE.PICK:
      return 'purple'
    case TRANSACTION_TYPE.PACK:
      return 'orange'
    case TRANSACTION_TYPE.SHIP:
      return 'sky'
    case TRANSACTION_TYPE.PUTAWAY:
      return 'cyan'
    case TRANSACTION_TYPE.RECEIVING_CREATION:
      return 'fuchsia'
    case TRANSACTION_TYPE.STATUS_CHANGED:
      return 'blue-gray'
    default:
      return 'maroon'
  }
}

export const removeDuplicateObjects = (array, key) => {
  return array?.reduce((acc, item) => {
    if (!acc.some((existingItem) => existingItem?.[key]?.toString() === item[key]?.toString())) {
      return [...acc, item]
    }
    return acc
  }, [])
}

export const convertToKilograms = (weight, weight_class_id) => {
  if (!weight || !weight_class_id) return 0
  const conversionFactor = WEIGHT_CONVERSION_FACTORS_TO_KG[+weight_class_id]
  return weight * conversionFactor
}

export const getMultiColor = (status) => {
  switch (parseInt(status)) {
    case 1:
      return 'blue'
    case 2:
      return 'teal'
    case 3:
      return 'indigo'
    case 4:
      return 'sky'
    case 5:
      return 'blue-gray'
    case 6:
      return 'purple'
    case 7:
      return 'orange'
    case 8:
      return 'primary'
    case 9:
      return 'info'
    case 10:
      return 'warning'
    case 11:
      return 'success'
    case 12:
      return 'yellow'
    case 13:
      return 'cyan'
    case 14:
      return 'danger'
    case 15:
      return 'maroon'
    case 16:
      return 'secondary'
    case 17:
      return 'rose'
    case 18:
      return 'blue'
    case 19:
      return 'teal'
    case 20:
      return 'indigo'
    case 21:
      return 'sky'
    case 22:
      return 'blue-gray'
    case 23:
      return 'purple'
    case 24:
      return 'orange'
    case 25:
      return 'primary'
    case 26:
      return 'info'
    case 27:
      return 'warning'
    case 28:
      return 'success'
    case 29:
      return 'yellow'
    case 30:
      return 'cyan'
    case 31:
      return 'danger'
    case 32:
      return 'maroon'
    case 33:
      return 'secondary'
    case 34:
      return 'rose'
    case 35:
      return 'blue'
    case 36:
      return 'teal'
    case 37:
      return 'indigo'
    case 38:
      return 'sky'
    case 39:
      return 'blue-gray'
    case 40:
      return 'purple'
    case 41:
      return 'orange'
    case 42:
      return 'primary'
    case 43:
      return 'info'
    case 44:
      return 'warning'
    case 45:
      return 'success'
    case 46:
      return 'yellow'
    case 47:
      return 'cyan'
    case 48:
      return 'danger'
    case 49:
      return 'maroon'
    case 50:
      return 'secondary'
    case 51:
      return 'rose'
    default:
      return 'dark'
  }
}

export const encodeValue = (value) => {
  if (!value) return ''
  return encodeURIComponent(value)
}

export const getStatusColor = (status) => {
  switch (parseInt(status)) {
    case COMMON_STATUS.ACTIVE:
      return 'success'
    case COMMON_STATUS.INACTIVE:
      return 'secondary'
    default:
      return 'maroon'
  }
}

export const truncateText = (text, maxLength = 33) => {
  const textString = text.toString()
  if (!textString || typeof textString !== 'string') return ''
  return textString.length > maxLength ? textString.slice(0, maxLength) + '…' : textString
}

export const setSortableColumns = (columns, sortableColumns) => {
  return columns?.map((column) => {
    return {
      ...column,
      sorter: sortableColumns.includes(column.key),
    }
  })
}
